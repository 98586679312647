import { mapActions, mapGetters } from 'vuex'
import VueCookies from 'vue-cookies'
import { formatFullDate, formatFullDateMinusDay, currencyFormat, isNotEmptyError, showVueToast, scrollToTheTarget } from '@/utils'
import { classFaq, programSuggestion } from '@/constant'
import { programSeo } from '@/constant/program-seo'
import { VueRecaptcha } from 'vue-recaptcha'

import dayjs from 'dayjs'
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
const CONFIG = {
  email: 'Email',
  name: 'Full name',
  password: 'Password',
  motivation: 'Motivation',
  mobilePhone: 'Phone number'
}

export default {
  name: 'program-detail',
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/icons/ArrowForward'),
    Time: () => import(/* webpackChunkName: "time" */ '@/components/icons/Time'),
    Location: () => import(/* webpackChunkName: "location" */ '@/components/icons/Location'),
    CheckMarkCircle: () => import(/* webpackChunkName: "check-mark-circle" */ '@/components/icons/CheckMarkCircle'),
    Education: () => import(/* webpackChunkName: "education" */ '@/components/icons/EduPlatforms'),
    CareerCounseling: () => import(/* webpackChunkName: "career-counseling" */ '@/components/icons/CareerCounseling'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Redeem: () => import(/* webpackChunkName: "redeem" */ '@/components/forms/Redeem'),
    // RedeemV2: () => import(/* webpackChunkName: "RedeemV2" */ '@/components/forms/RedeemV2'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Duration: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Duration'),
    Benefit: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Benefit'),
    Stacks: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Stacks'),
    Calendar: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Calendar'),
    CalendarFill: () => import(/* webpackChunkName: "calendar-fill" */ '@/components/icons/CalendarFill'),
    ClockPrakerja: () => import(/* webpackChunkName: "icon" */ '@/components/icons/ClockPrakerja'),
    Schedule: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Schedule'),
    Price: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Price'),
    Portfolio: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Portfolio'),
    Apprenticeship: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Apprenticeship'),
    Community: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Community'),
    Loyalty: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Loyalty'),
    SoftSkill: () => import(/* webpackChunkName: "icon" */ '@/components/icons/SoftSkill'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion'),
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    CreditCard: () => import(/* webpackChunkName: "credit-card" */ '@/components/icons/CreditCard'),
    Cash: () => import(/* webpackChunkName: "cash" */ '@/components/icons/Cash'),
    Rocket: () => import(/* webpackChunkName: "rocket" */ '@/components/icons/Rocket'),
    NewTestimonyCard: () => import(/* webpackChunkName: "accordion" */ '@/components/card/NewTestimonyCard'),
    TestimonyCard: () => import(/* webpackChunkName: "testimonycard" */ '@/components/card/TestimonyCard'),
    Run: () => import(/* webpackChunkName: "run" */ '@/components/icons/Run'),
    Variants: () => import(/* webpackChunkName: "variants" */ '../Variants'),
    VueRecaptcha
  },
  data() {
    return {
      isModalVoucherCodeVisible: false,
      showVoucherCodeField: false,
      voucherCode: '',
      voucherCodeTextFieldError: '',
      selectedVariant: null,
      coreClassVariants: [],
      outcomes: [],
      benefitsTitle: '',
      benefits: [],
      paymentMethodList: [
        { code: 'UPFRONT_CASH', name: 'Upfront Cash' },
        { code: 'PAY_AS_YOU_GO', name: 'Pay as you go' },
        { code: 'STUDENT_LOAN', name: 'Student Loan' },
        { code: 'ISA', name: 'ISA' }
      ],
      selectedPaymentMethod: null,
      partnerList: ['astrapay', 'gojek', 'gotoko', 'bri', 'btpns', 'doku', 'fifgroup', 'danamas'],
      partnerListMobileBased: ['astrapay', 'gojek', 'gotoko', 'bri', 'btpns', 'doku', 'fifgroup', 'danamas'],
      partnerListWebBased: [
        ['astrapay', 'gojek', 'gotoko', 'bri', 'btpns'],
        [
          {
            image: 'doku',
            style: 'h-20'
          },
          {
            image: 'fifgroup',
            style: 'h-16'
          },
          {
            image: 'danamas',
            style: 'h-12 ml-6'
          }
        ]
      ],
      buttonDisabled: false,
      isGeneralModal: false,
      isBookSession: false,
      isRegisterPrakerjaVisible: false,
      isLoadingRegister: false,
      visibleContent: false,
      isDownloading: false,
      faq: [],
      isError: false,
      programType: ['bootcamp', 'prakerja', 'regular', 'international', 'short-course'],
      prakerjaAdditionalInfo: [],
      carouselOptions: {
        autoplay: false,
        arrows: false,
        perPage: 4,
        speed: 1000,
        gap: '0.75rem',
        lazyLoad: 'nearby',
        pagination: false,
        breakpoints: {
          767: {
            perPage: 1,
            fixedWidth: '290px'
          }
        }
      },
      carouselSoftwareToolsOptions: {
        autoplay: true,
        arrows: false,
        perPage: 4,
        speed: 1000,
        gap: '0.75rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1,
            fixedWidth: '290px'
          }
        }
      },
      carouselTestimonyOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 2,
        speed: 1000,
        pagination: true,
        interval: 5000,
        gap: '1rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1
          }
        }
      },
      carouselPaymentOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        pagination: true,
        perPage: 4,
        speed: 1000,
        gap: '0.75rem',
        lazyLoad: 'nearby',
        breakpoints: {
          767: {
            perPage: 1,
            fixedWidth: '290px'
          }
        }
      },
      suggestions: programSuggestion,
      programIndex: 0,
      visibleRegistrationModal: false,
      registrationPayload: {
        name: null,
        email: null,
        password: null,
        motivation: null,
        variant: null,
        mobilePhone: null,
        paymentMethod: null
      },
      motivationList: ['Getting a job in Tech Industries', 'Switching careers into Tech', 'Gaining new knowledge (upskilling)', 'Prakerja', 'Others'],
      inputDisabled: false,
      isGeneralRegister: false,
      phoneInputType: 'number',
      typeInput: 'password',
      isTyping: false,
      privateConsultationId: null,
      selectedSchedule: null,
      notification: null,
      scheduleList: [],
      upcomingSchedule: [],
      errors: [],
      programWebDetail: {},
      customerId: 1, // G2
      formCode: 'shortCourseEnrollmentForm',
      formToken: '',
      errorValidation: {
        name: true,
        email: true,
        mobilePhone: true
      },
      contentBilingual: {},
      bookSessionWarning: false,
      paymentOptions: [],
      selectedTestimony: null,
      content: null,
      visibleGeneralInfoModal: false,
      visibleTestimonyModal: false,
      paymentMethod: [],
      isScheduleUpcomingAvailable: false,
      isCaptchaVerified: false
    }
  },
  filters: {
    currencyFormat
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },

  computed: {
    ...mapGetters('common', ['isMobile', 'adminB2C', 'adminGeneral']),
    ...mapGetters('auth', ['isLoggedIn']),
    priceDisplay() {
      if (this.selectedVariant) {
        return {
          price: currencyFormat(this.selectedVariant?.price),
          promo: currencyFormat(this.selectedVariant?.promo)
        }
      } else if (this.programWebDetail.coreClassVariants.length) {
        return {
          price: `${currencyFormat(this.programWebDetail.coreClassPriceRange?.minimumPrice)} - ${currencyFormat(this.programWebDetail.coreClassPriceRange?.maximumPrice)}`,
          promo: null
        }
      }
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
    bilingualPayment() {
      return this.$i18n.messages[this.activeLanguange].programDetails.payment
    },
    bilingualAdmissionProcess() {
      return this.$i18n.messages[this.activeLanguange].programDetails.admissionProcess.step
    },
    activeLanguange() {
      return this.$i18n.locale
    },
    computedPaymentMethod() {
      return this.paymentMethod.map((payment) => {
        return {
          title: this.bilingualPayment[payment].title,
          desc: this.bilingualPayment[payment].desc
        }
      })
    },
    admissionProcess() {
      let process = []
      if (this.programWebDetail.layoutType === 'COLLABORATION') {
        process = [
          {
            image: 'register',
            title: this.bilingualAdmissionProcess.takeNow.title,
            description: this.bilingualAdmissionProcess.takeNow.desc,
            additionalText: this.programTypeSelected
          },
          {
            image: 'join',
            title: this.bilingualAdmissionProcess.joinOpenDay.title,
            description: this.bilingualAdmissionProcess.joinOpenDay.desc
          },
          {
            image: 'program-consultation',
            title: this.bilingualAdmissionProcess.consultation.title,
            description: this.bilingualAdmissionProcess.consultation.desc
          },
          {
            image: 'pay-tuition-fee',
            title: this.bilingualAdmissionProcess.payTuitionFee.title,
            description: this.bilingualAdmissionProcess.payTuitionFee.desc,
            additionalText: this.programTypeSelected,
            paymentText: this.bilingualAdmissionProcess.payTuitionFee.paymentText
          },
          {
            image: 'start-learning',
            title: this.bilingualAdmissionProcess.startLearning.title,
            description: this.bilingualAdmissionProcess.startLearning.desc
          }
        ]
      } else if (this.isBootcamp) {
        process = [
          {
            image: 'register',
            title: this.bilingualAdmissionProcess.takeNow.title,
            description: this.bilingualAdmissionProcess.takeNow.desc,
            additionalText: this.programTypeSelected
          },

          {
            image: 'program-consultation',
            title: this.bilingualAdmissionProcess.consultation.title,
            description: this.bilingualAdmissionProcess.consultation.desc
          },

          {
            image: 'pay-tuition-fee',
            title: this.bilingualAdmissionProcess.payTuitionFee.title,
            description: this.bilingualAdmissionProcess.payTuitionFee.desc,
            additionalText: this.programTypeSelected,
            paymentText: this.bilingualAdmissionProcess.payTuitionFee.paymentText
          },

          {
            image: 'start-learning',
            title: this.bilingualAdmissionProcess.startLearning.title,
            description: this.bilingualAdmissionProcess.startLearning.desc
          }
        ]
      } else if (this.isShortCourse) {
        process = [
          {
            image: 'register',
            title: this.bilingualAdmissionProcess.takeNow.title,
            description: this.bilingualAdmissionProcess.takeNow.desc,
            additionalText: this.programTypeSelected
          },
          {
            image: 'pay-tuition-fee',
            title: this.bilingualAdmissionProcess.payTuitionFee.title,
            description: this.bilingualAdmissionProcess.payTuitionFee.desc,
            additionalText: this.programTypeSelected,
            paymentText: this.bilingualAdmissionProcess.payTuitionFee.paymentText
          },
          {
            image: 'start-learning',
            title: this.bilingualAdmissionProcess.startLearning.title,
            description: this.bilingualAdmissionProcess.startLearning.asc
          }
        ]
        if (this.programWebDetail.price === 0) {
          process.splice(1, 1)
        }
      }
      return process
    },
    instructorList() {
      const instructors = []
      if (this.programWebDetail.instructorClasses.length > 0) {
        for (let i = 0; i < this.programWebDetail.instructorClasses.length; i++) {
          const item = this.programWebDetail.instructorClasses[i]
          instructors.push({
            seqNo: item.seqNo,
            testimony: {
              id: item.id,
              message: item.expert.description,
              placement: item.expert.educationBackground,
              userName: item.expert.name,
              userPhoto: item.expert.photo
            }
          })
        }
      }
      return instructors
    },
    utm() {
      return this.$route.query.utm_source || 'Website'
    },
    utmCampaign() {
      return sessionStorage.getItem('utm_campaign')
    },
    utmSource() {
      return sessionStorage.getItem('utm_source')
    },
    utmMedium() {
      return sessionStorage.getItem('utm_medium')
    },
    environtment() {
      return process.env.NODE_ENV
    },
    programSlug() {
      return this.$route.params.slug
    },
    corporateTraining() {
      return this.$route.params.slug.substr(0, 18) === 'corporate-training'
    },
    eventB2C() {
      return this.$route.params.slug.substr(0, 5) === 'event'
    },
    classType() {
      return this.$route.params.category
    },
    isLoops() {
      return this.programSlug === 'loops'
    },
    isPramp() {
      return this.programSlug === 'pramp'
    },
    isPresentation() {
      return this.programSlug === 'effective-presentation-skills'
    },
    isRegular() {
      return this.programWebDetail.type === 'Regular'
    },
    isShortCourse() {
      return this.programWebDetail.type === 'Short Course'
    },
    isBootcamp() {
      return this.programWebDetail.type === 'Bootcamp'
    },
    isPrakerja() {
      return this.programWebDetail.type === 'Prakerja'
    },
    programTypeSelected() {
      return this.programWebDetail.type
    },
    filteredPrakerjaAdditionalInfo() {
      return this.prakerjaAdditionalInfo.filter((item) => item.value)
    },
    studentId() {
      return localStorage.getItem('student_id')
    },
    isComingSoon() {
      if (!this.programWebDetail.waitingListFeature && !this.isScheduleUpcomingAvailable) return true
      return false
    },
    disableRegisterBootcamp() {
      return this.forDisabled || !this.registrationPayload.motivation || !this.isCaptchaVerified || (this.coreClassVariants.length && !this.registrationPayload.variant)
    },
    forDisabled() {
      let forBoolean = true
      if (!this.errors['name'] && !this.errors['email'] && !this.errors['mobilePhone']) {
        if (!this.selectedSchedule && this.isBookSession) {
          forBoolean = true
        } else {
          forBoolean = false
        }
      } else {
        forBoolean = true
      }
      return !this.registrationPayloadName || !this.registrationPayloadEmail || !this.registrationPayloadMobilePhone || forBoolean
    },
    registrationPayloadName() {
      return this.registrationPayload.name
    },
    registrationPayloadEmail() {
      return this.registrationPayload.email
    },
    registrationPayloadMobilePhone() {
      return this.registrationPayload.mobilePhone
    },
    isErrorEmpty() {
      return Object.values(this.errorValidation).includes(true)
    },
    dataReady() {
      return Object.keys(this.programWebDetail).length > 0
    }
  },
  watch: {
    selectedVariant(val) {
      this.registrationPayload.variant = val
    },
    activeLanguange() {
      this.getClassInformation()
    },
    isBookSession: function (value) {
      if (value && this.scheduleList.length < 1) this.register(true)
    },
    formToken: function () {
      this.getScheduleList(this.isBookSession ? 'book-session' : 'upcoming-schedule')
    },
    registrationPayloadName: function (value) {
      if (!value) {
        this.errors['name'] = 'Name is required!'
        this.errorValidation.name = true
      } else {
        this.errors['name'] = undefined
        this.errorValidation.name = false
      }
    },
    registrationPayloadEmail(value) {
      if (!value) {
        this.errors['email'] = 'Email is required!'
        this.errorValidation.email = true
      } else {
        // eslint-disable-next-line
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (regex.test(value) === false) {
          this.errors['email'] = 'Email format is invalid!'
          this.errorValidation.email = true
        } else {
          this.errors['email'] = undefined
          this.errorValidation.email = false
        }
      }
    },
    registrationPayloadMobilePhone(value) {
      if (value && value.length >= 15) {
        this.registrationPayload.mobilePhone = value.slice(0, 15)
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value && (value[0] !== '0' || value[1] !== '8')) {
        this.errors['mobilePhone'] = 'Must start with 08xxxx'
        this.errorValidation.mobilePhone = true
      } else if (value && value.length < 10) {
        this.errors['mobilePhone'] = 'Minimum 10 digits, Maximum 15 digits'
        this.errorValidation.mobilePhone = true
      } else {
        this.errors['mobilePhone'] = undefined
        this.errorValidation.mobilePhone = false
      }
    }
  },
  created() {
    if (this.isLoggedIn) this.getProfile()
    this.scrollToTop()
    this.trackClass()
    this.programSlug === 'open-day' ? this.goToOpenDay() : this.getClassInformation()
    if (this.programSlug === 'fullstack-web') {
      window.fbq('track', 'ViewContent')
    }
  },
  methods: {
    openModalVoucherCode() {
      if (this.isShortCourse) {
        this.isModalVoucherCodeVisible = true
      } else {
        this.registerNow(false)
      }
    },
    closeModalVoucherCode() {
      this.isModalVoucherCodeVisible = false
      this.showVoucherCodeField = false
      this.voucherCode = ''
      this.voucherCodeTextFieldError = ''
    },
    toggleVoucherCodeField() {
      this.showVoucherCodeField = !this.showVoucherCodeField
    },
    confirmAndEnroll() {
      this.registerNow(false)
    },
    ...mapActions('auth', ['loginStudent', 'registerStudent', 'getCurrentUser']),
    ...mapActions('common', ['showLoading', 'hideLoading', 'sendWhatsApp']),
    ...mapActions('program', ['getProgramByWebSlug', 'downloadSyllabus', 'enroll', 'enrollV2', 'newEnroll', 'getFormToken', 'newEnrollV2', 'enrollBookSession']),
    ...mapActions('student', ['getStudentDetail', 'getSchedule']),
    selectVariant(variantId) {
      this.selectedVariant = this.coreClassVariants.filter((item) => item.variantId === variantId)[0]
      this.goToRegistration()
    },
    selectVariantFromDropdown(variant) {
      this.selectedVariant = variant
    },
    selectPaymentMethodDropdown(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod
    },
    verifyMethod() {
      this.isCaptchaVerified = true
    },
    expiredMethod() {
      this.isCaptchaVerified = false
    },
    goToRegistration() {
      this.isMobile ? scrollToTheTarget('reg', 180) : scrollToTheTarget('reg', 100)
    },
    askOurTeam() {
      this.sendWhatsApp({ number: this.adminB2C, topic: encodeURIComponent(this.programWebDetail.name) })
    },
    showDetail(item, isGeneral) {
      isGeneral ? (this.isGeneralModal = isGeneral) : (this.isGeneralModal = false)
      this.selectedTestimony = item
      this.toggleTestimonyModal()
    },
    showDetailGeneral(item) {
      this.content = item
      this.toggleGeneralInfoModal()
    },
    toggleTestimonyModal() {
      this.visibleTestimonyModal = !this.visibleTestimonyModal
    },
    toggleGeneralInfoModal() {
      this.visibleGeneralInfoModal = !this.visibleGeneralInfoModal
    },
    registerNow(value) {
      value && !this.isPrakerja ? (this.isBookSession = true) : (this.isBookSession = false)
      if (this.isPrakerja) {
        this.$router.push('/prakerja/redeem')
      } else {
        if (this.isLoggedIn && !this.isBookSession) {
          this.isBootcamp ? (this.programWebDetail.waitingListFeature ? this.register() : this.goToOpenDay()) : this.register()
        } else {
          if (this.isBootcamp) {
            this.programWebDetail.waitingListFeature ? this.toggleRegistrationModal() : this.goToOpenDay()
          } else {
            localStorage.setItem('private-consultation', this.privateConsultationId)
            this.toggleRegistrationModal()
          }
        }
      }
    },
    careerCoachingPage() {
      this.$router.push('/career-counseling')
    },
    goToDetail(item) {
      if (item.title === 'Cash') {
        this.sendWhatsApp({ number: this.adminB2C, topic: `Pembiayaan ${this.programWebDetail.type} Secara Cash` })
      } else if (item.title === 'Credit Card' || item.title === 'Kartu Kredit') {
        this.sendWhatsApp({ number: this.adminB2C, topic: `Pembiayaan ${this.programWebDetail.type} Via Kartu Kredit` })
      } else if (item.title === 'ISA') {
        this.$router.push('/isa')
      } else if (item.title === 'Scholarship' || item.title === 'Beasiswa') {
        this.$router.push('/tech-champ-scholarship')
      } else if (item.title === 'Installment' || item.title === 'Cicilan') {
        this.$router.push('/installment')
      } else if (item.title === 'Pay As You Go') {
        this.sendWhatsApp({ number: this.adminB2C, topic: `Pembiayaan ${this.programWebDetail.type} Secara Pay As You Go` })
      }
    },
    enrollProgram(token) {
      this.showLoading()
      const payloads = {
        coreClassId: this.programWebDetail.id,
        email: this.registrationPayload.email,
        name: this.registrationPayload.name,
        mobilePhone: this.registrationPayload.mobilePhone,
        voucher_code: this.voucherCode
      }
      this.newEnrollV2({
        payloads: {
          ...payloads,
          ...(this.utmSource && { utmSource: this.utmSource }),
          ...(this.utmCampaign && { utmCampaign: this.utmCampaign }),
          ...(this.utmMedium && { utmMedium: this.utmMedium })
        },
        formToken: token
      }).then(
        () => {
          this.showVoucherCodeField = false
          this.closeModalVoucherCode()
          this.resetForm()
          this.isTyping = false
          this.notification = null
          this.isGeneralRegister = false
          this.errors = []
          this.visibleRegistrationModal = false
          this.hideLoading()
          const typeUrl = this.programWebDetail.type.replace(/ /g, '-').toLowerCase()
          setTimeout(() => {
            this.$router.push(`/thankyou/${typeUrl}`)
          }, 500)
        },
        (error) => {
          // this.hideLoading()
          this.ifError(error)
        }
      )
    },
    redeemSuccess() {
      this.toggleRegisterPrakerjaModal()
      setTimeout(() => {
        this.$router.push('/thankyou/prakerja')
      }, 500)
    },
    goToOpenDay() {
      switch (this.programSlug) {
        case 'digital-marketing':
          this.$router.push('/class/open-day/self-service-open-day-dm')
          break
        default:
          this.$router.push('/class/open-day/self-service-open-day')
          break
      }
    },
    emailCheck(email) {
      // eslint-disable-next-line
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (regex.test(email) === false) {
        this.errors['email'] = 'Email format is invalid!'
      }
      return regex.test(email)
    },
    // enrollProgramV2() {
    //   this.errors = []
    //   if (this.validForm() && this.emailCheck(this.registrationPayload.email)) {
    //     this.showLoading()
    //     const payloads = {
    //       customerId: this.customerId,
    //       formCode: this.formCode,
    //       name: this.registrationPayload.name,
    //       email: this.registrationPayload.email,
    //       mobilePhone: this.registrationPayload.mobilePhone,
    //       programCode: this.programWebDetail.type,
    //       coreClassId: this.programWebDetail.id
    //     }
    //     this.enrollV2({
    //       payloads: payloads
    //     }).then(() => {
    //       this.hideLoading()
    //       const typeUrl = this.programWebDetail.type.replace(/ /g, '-').toLowerCase()
    //       this.$router.push(`/thankyou/${typeUrl}`)
    //     })
    //   }
    // },
    getProfile() {
      if (this.studentId) {
        this.showLoading()
        this.getStudentDetail({ studentId: this.studentId })
          .then((res) => {
            this.registrationPayload.name = res.name
            this.registrationPayload.mobilePhone = res.phoneNumber
            this.registrationPayload.email = res.email
            this.inputDisabled = true
            this.hideLoading()
          })
          .catch(() => {
            this.hideLoading()
          })
      }
    },
    addDay() {
      var date = new Date()
      var dayBefore = this.programWebDetail.schedulePrices[0].dayBeforeSchedule
      date.setDate(date.getDate() + dayBefore)
    },
    getScheduleList(scheduleFor) {
      const params = {
        customer_id: this.customerId,
        core_class_id: this.privateConsultationId,
        sortBy: 'startAt',
        max_participants_not_yet_full: true
      }
      if (scheduleFor === 'upcoming-schedule') {
        params.core_class_id = this.programWebDetail.id
        params.page = 0
        params.size = 2
      }
      this.getSchedule({
        params,
        formToken: this.formToken
      }).then((response) => {
        if (scheduleFor === 'book-session') {
          this.scheduleList = response.data.data
        } else {
          this.upcomingSchedule = []
          if (response?.data?.data && response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              this.upcomingSchedule.push({
                name: response.data.data[i].nama ? response.data.data[i].nama : '-',
                startDate: response.data.data[i].start_date ? formatFullDate(response.data.data[i].start_date) : '-',
                schedule: this.programWebDetail && this.programWebDetail.content[0]?.schedule ? this.programWebDetail.content[0]?.schedule : '-',
                closedRegistrationDate: response.data.data[i].start_date ? formatFullDateMinusDay(response.data.data[i].start_date, 7) : '-'
              })
            }
          }
        }
      })
    },
    getScheduleUpcoming(customerId) {
      this.getFormToken()
        .then((response) => {
          const formToken = response.data.data
          const params = {
            customer_id: customerId,
            core_class_id: this.programWebDetail.id,
            sortBy: 'startAt',
            sort_direction: 'DESC',
            page: 0,
            size: 10,
            upcoming: false
          }
          this.getSchedule({
            params,
            formToken
          }).then((res) => {
            if (res?.status === 200) {
              this.isScheduleUpcomingAvailable = res.data.data.length > 0
            }
          })
        })
        .catch(() => showVueToast('There was a problem, please try again later!', 'error', 3000))
    },
    validForm() {
      if (this.isBootcamp) {
        if (!this.isGeneralRegister) {
          delete this.registrationPayload.name
          delete this.registrationPayload.mobilePhone
          delete this.registrationPayload.motivation
        }
      } else {
        delete this.registrationPayload.password
        delete this.registrationPayload.motivation
      }
      const error = isNotEmptyError(this.registrationPayload)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    },
    ifSuccess() {
      this.hideLoading()
      this.$router.push(`/thankyou/${this.isBootcamp ? 'bootcamp' : this.$route.params.slug}`)
    },
    ifError(error) {
      const errorMessage = error?.response?.data?.errors?.error
      const errorStatus = error?.response?.status

      this.voucherCodeTextFieldError = errorMessage
      let message = ''
      if (errorStatus === 404) {
        message = 'Diagnostic Assessment schedule is not available. Please contact our Customer Service!'
      } else if (errorStatus === 500) {
        message = 'You have already taken this program, please check your dashboard!'
      } else if (errorStatus === 422 && errorMessage === 'Schedule is not available') {
        this.resetForm()
        this.isTyping = false
        this.notification = null
        this.isGeneralRegister = false
        this.errors = []
        this.visibleRegistrationModal = false
        this.hideLoading()
        setTimeout(() => {
          this.$router.push('/thankyou/waiting-list')
        }, 500)
        return
      } else if (errorStatus === 400) {
        message = errorMessage
      } else {
        message = 'Program registration failed, please try again!'
      }
      this.hideLoading()
      if (this.voucherCodeTextFieldError?.length === 0 || message !== '') {
        showVueToast(message, 'error', 3000)
      }
      setTimeout(() => {
        this.buttonDisabled = false
        this.hideLoading()
      }, 3000)
    },
    doLogin() {
      this.errors = []
      if (this.validForm() && this.emailCheck(this.registrationPayload.email)) {
        this.showLoading()
        this.loginStudent({
          payloads: {
            email: this.registrationPayload.email,
            password: this.registrationPayload.password,
            formCode: 'studentLogin'
          }
        }).then(
          (response) => {
            if (response.data.code === 200) {
              this.setUserData(response.data.data)
            } else if (response.data.code === 422) {
              this.notification = 'Email atau password yang anda masukkan salah!'
            } else if (response.data.code === 400) {
              this.notification = response.data.errors.error
            } else {
              this.notification = 'Login gagal, harap mencoba beberapa saat lagi!'
            }
            this.hideLoading()
          },
          () => {
            this.hideLoading()
            this.notification = 'Email atau password yang anda masukkan salah!'
          }
        )
      }
    },
    async setUserData(response) {
      const utm = [localStorage.getItem('utm_campaign'), localStorage.getItem('utm_medium'), localStorage.getItem('utm_source')]
      await localStorage.clear()
      if (utm[0]) {
        await localStorage.setItem('utm_campaign', utm[0])
      }
      if (utm[1]) {
        await localStorage.setItem('utm_medium', utm[1])
      }
      if (utm[2]) {
        await localStorage.setItem('utm_source', utm[2])
      }
      if (this.environtment === 'development') {
        await VueCookies.set('token', response.token)
      } else {
        await VueCookies.set('token', response.token, null, null, '.g2academy.co')
      }
      await localStorage.setItem('access_token', response.token)
      await localStorage.setItem('user_name', response.name)
      await localStorage.setItem('user_id', response.id)
      if (response.roles.includes('STUDENT')) {
        await localStorage.setItem('user_roles', 'STUDENT')
      }
      await this.getCurrentUser()
      this.enrollProgram()
    },
    register(isOnlyGetToken) {
      if (!this.isLoadingRegister) {
        this.isLoadingRegister = true
        this.getFormToken()
          .then((response) => {
            if (isOnlyGetToken) {
              this.formToken = response.data.data
            } else {
              this.isBootcamp ? this.doGeneralRegister(response.data.data) : this.enrollProgram(response.data.data)
            }
          })
          .catch((e) => {
            showVueToast('There was a problem, please try again later!', 'error', 3000)
          }).finally(() => {
            this.isLoadingRegister = false
          })
      }
    },
    doGeneralRegister(token) {
      this.buttonDisabled = true
      this.showLoading()
      let enrollProgram = () => { }
      let forThankYouPage = ''
      if (this.isBookSession) {
        enrollProgram = this.enrollBookSession
        forThankYouPage = 'book-session'
      } else {
        enrollProgram = this.newEnrollV2
        forThankYouPage = 'register'
      }

      const payloads = {
        coreClassId: this.isBookSession ? this.privateConsultationId : this.programWebDetail.id,
        email: this.registrationPayload.email,
        name: this.registrationPayload.name,
        mobilePhone: this.registrationPayload.mobilePhone,
        voucherCode: this.voucherCode
      }
      const submittedPayload = {
        ...payloads,
        ...(this.registrationPayload.motivation && { motivation: this.registrationPayload.motivation }),
        ...(this.isBookSession && { lmsClassId: this.selectedSchedule.id }),
        ...(this.utmSource && { utmSource: this.utmSource }),
        ...(this.utmCampaign && { utmCampaign: this.utmCampaign }),
        ...(this.utmMedium && { utmMedium: this.utmMedium }),
        ...(this.registrationPayload?.variant && Object.keys(this.registrationPayload?.variant).length && { variantId: this.registrationPayload?.variant?.variantId }),
        ...(this.registrationPayload?.paymentMethod && Object.keys(this.registrationPayload?.paymentMethod).length && { paymentMethod: this.registrationPayload?.paymentMethod?.code })
      }

      enrollProgram({
        payloads: submittedPayload,
        formToken: token
      }).then(
        () => {
          // if (!this.isLoggedIn) showVueToast('Registrasi akun anda berhasil!', 'success', 3000)
          if (!this.isBootcamp) this.toggleRegistrationModal()
          setTimeout(() => {
            this.hideLoading()
            this.buttonDisabled = false
            this.$router.push(`/thankyou/${forThankYouPage}`)
          }, 3500)
        },
        (error) => this.ifError(error)
      )
    },
    resetForm() {
      this.registrationPayload = {
        name: null,
        email: null,
        password: null,
        motivation: null,
        mobilePhone: null
      }
    },
    scrollToTop: function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    trackClass: function () {
      switch (this.courseId) {
        case '4cd27d85-8998-4cf8-862e-0544ab0c8f0c':
          // eslint-disable-next-line
          gtag('event', 'conversion', {
            send_to: 'AW-752316066/9p4cCLuarpgBEKLd3eYC'
          })
          break
        case '9b24b1e6-1b59-43a3-82cd-0beb2332db72':
          // eslint-disable-next-line
          gtag('event', 'conversion', {
            send_to: 'AW-752316066/Sg4QCP-crpgBEKLd3eYC'
          })
          break
        case '0b8fa93d-40ae-453e-ae2e-0f960e89935c':
          // eslint-disable-next-line
          gtag('event', 'conversion', {
            send_to: 'AW-752316066/32x2CJ_Fo5gBEKLd3eYC'
          })
          break
        case 'f73c24d0-7ad1-4ef8-baa7-9bd74b44eacf':
          // eslint-disable-next-line
          gtag('event', 'conversion', {
            send_to: 'AW-752316066/8VZ2CIiut5gBEKLd3eYC'
          })
          break
        default:
          break
      }
    },
    getClassInformation: function () {
      this.visibleContent = false
      if (!this.programType.includes(this.classType)) {
        this.isError = true
        return
      }

      const params = {
        lang: this.activeLanguange,
        variantIsActive: true // true means active only, false means inactive only, null / undefined will show both
      }

      this.getProgramByWebSlug({
        slug: this.programSlug,
        params
      })
        .then(this.success)
        .catch(() => {
          this.isError = true
        })
    },
    success: function (result) {
      if (result.data.data.layoutType === 'COLLABORATION') return this.$router.replace({ path: `/class/collaboration/${result.data.data.type.toLowerCase()}/${result.data.data.slug}` })
      if (result.data.data.layoutType === 'EVENT_LAYOUT') return this.$router.push(`event/${this.programSlug}`)
      const data = result.data.data
      this.customerId = data.customerId
      this.getScheduleUpcoming(data.customerId)
      this.visibleContent = true
      if (data.content.length === 0) {
        this.$i18n.locale = 'en'
        return
      }

      const length = data.content[0].sylabusClasses?.length
      if (length >= 4) {
        this.carouselOptions.perPage = 4
        this.carouselOptions.width = '100%'
        if (length > 4) this.carouselOptions.pagination = true
      } else {
        this.carouselOptions.perPage = length
        if (this.isMobile) {
          this.carouselOptions.width = '100%'
        } else {
          this.carouselOptions.width = (length * 100) / 4 + '%'
        }
      }
      this.programWebDetail = data
      this.coreClassVariants = data.coreClassVariants
      if (this.isPrakerja) {
        this.prakerjaAdditionalInfo = [
          {
            title: 'Prasyarat',
            value: data.prasyarat
          },
          {
            title: 'Catatan',
            value: data.note
          },
          {
            title: 'Aspek Kompetensi Pengetahuan',
            value: data.knowledgeCompetence
          },
          {
            title: 'Aspek Kompetensi Keterampilan',
            value: data.skillCompetence
          },
          {
            title: 'Aspek Kompetensi Sikap',
            value: data.attitudeCompetence
          },
          {
            title: 'Sasaran Peserta',
            value: data.targetParticipant
          },
          {
            title: 'Tujuan Pelatihan',
            value: data.purpose
          },
          {
            title: 'Kapasitas Jenis / Kelas Pelatihan',
            value: data.capacity
          },
          {
            title: 'Metode Ajar / Latih dan Evaluasi Peserta',
            value: data.learningMethod
          },
          {
            title: 'Jenis Klarifikasi Sertifikat',
            value: data.certificateClarification
          },
          {
            title: 'Aktivitas Pelatihan',
            value: data.activity
          }
        ]
      } else if (this.isBootcamp) {
        this.programIndex = 0
        this.getProgramByWebSlug({ slug: 'private-consultation' })
          .then((res) => (this.privateConsultationId = res.data.data.id))
          .catch(() => (this.isError = true))
      } else if (this.isLoops) {
        this.programIndex = 1
      } else if (this.isPramp) {
        this.programIndex = 2
      }
      if (data.layoutType === 'CLASS_SCHEDULE_LAYOUT') this.register(true)
      if (data.classPaymentMethod.length > 0) {
        this.paymentMethod = data.classPaymentMethod.map((payment) => payment.code.replace(/\s/g, '').toUpperCase())
        if (data.classPaymentMethod.length < 5) {
          this.carouselPaymentOptions.pagination = false
        }

        if (data.classPaymentMethod.length >= 3 && this.isMobile) {
          this.carouselPaymentOptions.pagination = true
        }
      }
      if (data.content[0].coreClassFaqs && data.content[0].coreClassFaqs.length > 0) {
        this.faq = []
        for (let i = 0; i < data.content[0].coreClassFaqs.length; i++) {
          const faq = data.content[0].coreClassFaqs[i]
          this.faq.push({
            id: i,
            text: faq.question,
            active: false,
            submenu: {
              message: faq.answer
            }
          })
        }
      }
      if (data.content[0].coreClassOutCome && data.content[0].coreClassOutCome.length > 0) {
        data.content[0].coreClassOutCome[0].outComesName ? (this.outcomes = data.content[0].coreClassOutCome[0].outComesName.split(',')) : (this.outcomes = [])
        data.content[0].coreClassOutCome[0].benefitTitle ? (this.benefitsTitle = data.content[0].coreClassOutCome[0].benefitTitle) : (this.benefitsTitle = '')
        data.content[0].coreClassOutCome[0].benefitItems && data.content[0].coreClassOutCome[0].benefitItems.length > 0
          ? (this.benefits = data.content[0].coreClassOutCome[0].benefitItems)
          : (this.benefits = [])
      }
    },
    forScheduledPromo(start, end) {
      const today = dayjs().format('DD MMMM YYYY HH:mm')
      const startTime = dayjs(start).format('DD MMMM YYYY HH:mm')
      const endTime = dayjs(end).format('DD MMMM YYYY HH:mm')
      const firstValue = dayjs(startTime).isSameOrBefore(today)
      const secondValue = dayjs(today).isSameOrBefore(endTime)
      return firstValue && secondValue
    },
    isDownloadableSyllabus: function () {
      return this.programWebDetail.syllabus && this.programWebDetail.syllabus !== ''
    },
    requestSyllabus() {
      if (!this.isLoggedIn) {
        if (this.isPrakerja) {
          this.$router.push(`/login?ref=${window.location.pathname}#prakerja`)
          return
        } else {
          this.$router.push(`/login?ref=${window.location.pathname}#academy`)
          return
        }
      }
      if (this.programWebDetail.syllabus !== '') {
        window.open(this.programWebDetail.syllabus)
      }
    },
    toggleRegisterPrakerjaModal() {
      this.isRegisterPrakerjaVisible = !this.isRegisterPrakerjaVisible
    },
    toggleGeneralRegister() {
      if (!this.isLoggedIn) this.resetForm()
      this.isTyping = false
      this.notification = null
      this.errors = []
      this.isGeneralRegister = !this.isGeneralRegister
    },
    showPassword(status) {
      status ? (this.typeInput = 'text') : (this.typeInput = 'password')
    },
    userIsTyping(e) {
      e.length > 0 ? (this.isTyping = true) : (this.isTyping = false)
    },
    toggleRegistrationModal() {
      if (!this.isLoggedIn) this.resetForm()
      this.isTyping = false
      this.notification = this.selectedSchedule = null
      this.isGeneralRegister = false
      this.errors = []
      this.visibleRegistrationModal = !this.visibleRegistrationModal
      if (this.isBookSession && this.visibleRegistrationModal) {
        this.bookSessionWarning = true
      }
    }
  }
}
